export const fonts = {
    passion: 'Passion One',
    alfa: 'Alfa Slab One',
    robotomono: 'Roboto Mono',
    inconsolata: 'Inconsolata',
    open: 'Open Sans',
    roboto: 'Roboto',
    monument: 'MonumentExtended-Regular',
    monumentBold: 'MonumentExtended-Ultrabold',
    header: '',
    body: '',
}

fonts.header = fonts.monument
fonts.body = fonts.roboto

export const colors = {
    nav: '#212121',
    body: '#151515',
    section1: '#121212',
    section2: '#0a0a0a',
    text: '#aaa',
    textHeader: '#ccc',
    accent: '',
    lines: '#aaa',

    blue: '#339AF0',
    green: '#03C04A',
    red: '#f5414E',
    orange: '#F95C00',
    yellow: '#eFcF00',
    purple: '#6D3Ea4',
    react: '#61dafb',
}

colors.accent = colors.blue

export const maxSkillsHeight = 9999999
export const maxWidth = 480
// const padding = '32px'

export const maxWidthDesktop = 800
export const maxWidth11ProMax = 300
export const maxWidth8Base = 260

export function getScrollPercent(element: HTMLDivElement) {
    return (100 * element.scrollTop) / (element.scrollHeight - element.clientHeight)
}

export function setAttributes(arr: HTMLCollectionOf<Element>, transform: string, opacity?: number) {
    for (let j = 0; j < arr.length; j++) {
        const el = arr[j] as HTMLElement
        el.style.transform = transform
        if (opacity !== undefined) {
            el.style.opacity = opacity.toString()
        }
    }
}
