import React, { useRef } from 'react'
import { colors, fonts, maxWidth } from '../../utils'
import { toRubberBandLetters } from './InternalUtils'
import { onScroll } from './OnScroll'

// const financireLinks = {
//     web: 'https://financire.com/',
//     ios: 'https://apps.apple.com/app/id1504483911',
//     android: 'https://play.google.com/store/apps/details?id=com.sidgo.financire',
// };
// const onequranLinks = {
//     web: 'https://onequran.app/',
//     ios: 'https://apps.apple.com/app/id1506937467',
//     android: 'https://play.google.com/store/apps/details?id=sidgo.onequran.app',
//     windows: 'https://www.microsoft.com/store/apps/9P4703BSH4ML',
// };
// const onekeepLinks = {
//     android: 'https://play.google.com/store/apps/details?id=com.sidgo.agenda',
//     windows: 'https://www.microsoft.com/store/apps/9NLQLCG03PCK',
// };

export function Home() {
    const refs = useRef({} as Record<string, HTMLDivElement | undefined | null>)
    const lastTopIndex = useRef(0)

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            {/* {sidebar} */}

            <div
                ref={ref => (refs.current['root'] = ref)}
                className=''
                style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    flex: 1,
                    backgroundColor: colors.body,
                    color: colors.text,
                    // overflow: 'auto',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    fontFamily: fonts.body,
                }}
                onScroll={e => {
                    // const scroll = (getScrollPercent(e.currentTarget!))
                    // const scrollbar = refs.current['scroll']
                    // if (scrollbar) {
                    //     scrollbar.style.height = `${(scroll)}vh`
                    // }

                    const root = refs.current['root']
                    if (root) {
                        onScroll(root, refs, lastTopIndex)
                    }
                }}
            >
                <div
                    id='intro'
                    className='p8'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        // backgroundColor: '#2e2e2e',
                        minHeight: '100vh',
                        // backgroundColor: colors.section1,
                        backgroundColor: 'black',
                    }}
                >
                    <div style={{}}>
                        <h1
                            className='size10'
                            ref={ref => (refs.current['intro1'] = ref)}
                            style={{
                                fontWeight: 'bold',
                                // fontSize: 100,
                                fontFamily: fonts.header,
                                color: 'white',
                            }}
                        >
                            {toRubberBandLetters(`Hello.\nI am Sid.`)}
                        </h1>
                        <p
                            ref={ref => (refs.current['intro2'] = ref)}
                            style={{
                                maxWidth: maxWidth,
                            }}
                        >
                            Full-stack web developer{' '}
                            <span
                                style={{
                                    fontFamily: fonts.monumentBold,
                                    // color: colors.accent,
                                    // fontWeight: 'bold',
                                    // fontSize: 18,
                                }}
                            >
                                +
                            </span>{' '}
                            Mobile developer with 7 years of professional software development experience, and currently a
                            <span
                                style={{
                                    fontFamily: fonts.header,
                                    // color: colors.accent,
                                }}
                            >
                                {' '}
                                senior full-stack developer{' '}
                            </span>
                            in a BPO company in the Philippines.
                            {/* Also a certified public
              accountant (CPA). */}
                        </p>
                    </div>
                </div>

                <div
                    id='contact'
                    className='p8'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'black',
                        // minHeight: '100vh',
                    }}
                >
                    <div
                        className='size5'
                        style={{
                            fontFamily: fonts.header,
                            // fontSize: 36,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <p
                            className='up'
                            style={{
                                maxWidth: maxWidth * 1.5,
                            }}
                        >
                            I'm always open to hearing new opportunities.
                        </p>
                        {/* <br /> */}
                        <a
                            className='up2'
                            href={`mailto:sid@sidgo.dev`}
                            style={{
                                color: 'white',
                            }}
                        >
                            {toRubberBandLetters(`Let's talk.`, 'sg-hoverunderline')}
                        </a>
                    </div>
                </div>
            </div>

            {/* <div ref={ref => refs.current['scroll'] = ref} style={{
                // height: `${Math.ceil(scroll)}vh`,
                height: 0,
                width: 4,
                backgroundColor: colors.textHeader,
                position: 'absolute',
                right: 0,
                top: 0,
            }} /> */}
        </div>
    )
}

// export function Home() {
//     const refs = useRef({} as Record<string, HTMLDivElement | undefined | null>)
//     const lastTopIndex = useRef(0)

//     return (
//         <div
//             style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 alignItems: 'stretch',
//                 height: '100%',
//                 overflow: 'hidden',
//             }}
//         >
//             {/* {sidebar} */}

//             <div
//                 ref={ref => (refs.current['root'] = ref)}
//                 className=''
//                 style={{
//                     // display: 'flex',
//                     // flexDirection: 'column',
//                     flex: 1,
//                     backgroundColor: colors.body,
//                     color: colors.text,
//                     // overflow: 'auto',
//                     overflowX: 'hidden',
//                     overflowY: 'auto',
//                     fontFamily: fonts.body,
//                 }}
//                 onScroll={e => {
//                     // const scroll = (getScrollPercent(e.currentTarget!))
//                     // const scrollbar = refs.current['scroll']
//                     // if (scrollbar) {
//                     //     scrollbar.style.height = `${(scroll)}vh`
//                     // }

//                     const root = refs.current['root']
//                     if (root) {
//                         onScroll(root, refs, lastTopIndex)
//                     }
//                 }}
//             >
//                 <div
//                     id='intro'
//                     className='p8'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         justifyContent: 'flex-start',
//                         alignItems: 'center',
//                         // flexWrap: 'wrap',
//                         // backgroundColor: '#2e2e2e',
//                         minHeight: '100vh',
//                         // backgroundColor: colors.section1,
//                         backgroundColor: 'black',
//                     }}
//                 >
//                     <div style={{}}>
//                         <h1
//                             className='size10'
//                             ref={ref => (refs.current['intro1'] = ref)}
//                             style={{
//                                 fontWeight: 'bold',
//                                 // fontSize: 100,
//                                 fontFamily: fonts.header,
//                                 color: 'white',
//                             }}
//                         >
//                             {toRubberBandLetters(`Hello.\nI am Sid.`)}
//                         </h1>
//                         <p
//                             ref={ref => (refs.current['intro2'] = ref)}
//                             style={{
//                                 maxWidth: maxWidth,
//                             }}
//                         >
//                             Full-stack web developer{' '}
//                             <span
//                                 style={{
//                                     fontFamily: fonts.monumentBold,
//                                     // color: colors.accent,
//                                     // fontWeight: 'bold',
//                                     // fontSize: 18,
//                                 }}
//                             >
//                                 +
//                             </span>{' '}
//                             Mobile developer with 7 years of professional software development experience, and currently a
//                             <span
//                                 style={{
//                                     fontFamily: fonts.header,
//                                     // color: colors.accent,
//                                 }}
//                             >
//                                 {' '}
//                                 senior full-stack developer{' '}
//                             </span>
//                             in a BPO company in the Philippines.
//                             {/* Also a certified public
//               accountant (CPA). */}
//                         </p>
//                     </div>
//                 </div>

//                 <div
//                     id='ios'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         justifyContent: 'space-around',
//                         flexWrap: 'wrap',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             className='up'
//                             icon={faAppStore}
//                             iconColor={colors.blue}
//                             header={
//                                 // <Fragment>
//                                 `iOS\nDeveloper`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I've been developing iOS apps since 2015. I can program in both Swift and Objective-C, but I do prefer Swift in writing native iOS
//                             apps.
//                         </p>
//                     </div>
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header className='left' header='Languages:' />

//                         <p className='left'>Swift, Objective-C</p>

//                         <Header
//                             header='Technologies:'
//                             className='left mt5'
//                             style={
//                                 {
//                                     // marginTop: 32
//                                 }
//                             }
//                         />

//                         <p className='left'>
//                             SwiftUI, core data, XCTest, cocoapods, carthage, swift package manager, rxswift, rxcocoa, GCD, AFNetworking, Alamofire, swiftyJSON,
//                             UIKit, autolayout, storyboards, XIB, schemes, workspaces, SDWebImage, Kingfisher, sqlite, realm, key-value coding, cocoa bindings,
//                             firebase, appium, firestore, collection views, table views, HIG, MVC
//                         </p>
//                     </div>
//                     {/* <div
//                         className='p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Statistics:' className='left' />
//                         <ul className='left'>
//                             {[
//                                 ['6', 'years of professional experience'],
//                                 ['10+', 'iOS apps I helped develop for my employers and clients'],
//                                 ['5', 'iOS apps I personally built from scratch and are available in iOS App Store'],
//                                 ['20+', 'iOS apps in total that I personally wrote, including those in-development and those discontinued'],
//                             ].map(([prefix, suffix]) => (
//                                 <li>
//                                     <span
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             fontWeight: 'bold',
//                                         }}
//                                     >
//                                         {prefix}
//                                     </span>
//                                     <span> {suffix}</span>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div> */}
//                 </div>

//                 <div
//                     id='android'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         justifyContent: 'space-around',
//                         flexWrap: 'wrap',
//                         backgroundColor: colors.section1,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faAndroid}
//                             className='up'
//                             iconColor={colors.green}
//                             header={
//                                 // <Fragment>
//                                 `Android\nDeveloper`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I've been developing Android apps since 2013 initially using Java, until around 2017 when I switched to Kotlin.
//                         </p>
//                     </div>
//                     <div
//                         className='left p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Languages:' />

//                         <p>Kotlin, Java</p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>
//                             Jetpack, dagger, espresso, junit, rxjava, rxandroid, retrofit, gradle, groovy DSL, android architecture components, room, realm,
//                             sqlite, firestore, firebase, view pager, recyclerview, fragments, material design, threads, loopers, handlers, asynctask, glide,
//                             picasso, android ktx, gson, butterknife, coroutines, MVP, MVVM, MVI, proguard, spannables, data binding, slices, moshi, view
//                             binding, mockito, robolectric
//                         </p>
//                     </div>
//                     {/* <div
//                         className='left p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Statistics:' />
//                         <ul>
//                             {[
//                                 ['8', 'years of building Android apps'],
//                                 ['10+', 'Android apps I helped build in a professional engagement'],
//                                 ['9', 'Android apps I personally built from scratch and are available in the Play Store'],
//                                 ['20+', 'Android apps in total that I personally wrote'],
//                             ].map(([prefix, suffix]) => (
//                                 <li>
//                                     <span
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             fontWeight: 'bold',
//                                         }}
//                                     >
//                                         {prefix}
//                                     </span>
//                                     <span> {suffix}</span>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div> */}
//                 </div>

//                 <div
//                     id='cross'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row-reverse',
//                         flexWrap: 'wrap',
//                         justifyContent: 'space-around',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 2,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faReact}
//                             className='up'
//                             iconColor={colors.react}
//                             header={
//                                 // <Fragment>
//                                 `Cross-Platform\nMobile Developer`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I can build mobile apps from scratch with the native SDK, but I prefer developing apps with cross-platform tools as they cut the
//                             development time by more than 50%.
//                         </p>
//                     </div>
//                     <div
//                         className='right mt5 p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Languages:' />

//                         <p>Typescript, JavaScript, C#, Dart</p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>React Native, Xamarin Native, Xamarin Forms, Flutter, XAML, .NET Standard</p>
//                     </div>
//                 </div>

//                 <div
//                     id='frontend'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'space-around',
//                         backgroundColor: colors.section1,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faChrome}
//                             iconColor={colors.yellow}
//                             className='up'
//                             header={
//                                 // <Fragment>
//                                 `Front-end\nWeb Developer`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I also have an extensive experience in building websites as I have ported most of my mobile apps into the web platform because web
//                             apps significantly reduce the friction in acquiring new users as they don't need to be installed unlike mobile apps.
//                         </p>
//                     </div>
//                     <div
//                         className='left p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Languages:' />

//                         <p>Typescript, JavaScript, Dart, Elm</p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>
//                             React, angular, redux, redux-thunk, redux-sagas, angular dart, webpack, babel, bootstrap, material design, mocha, jest, jquery, PWA,
//                             responsive, offline, mobile-first, rx, SEO, service workers, indexDB, flow, font awesome, CSS, HTML, SASS, LESS
//                         </p>
//                     </div>

//                     {/* <div
//                         className='left p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Statistics:' />
//                         <ul>
//                             {[
//                                 ['8', 'years of developing websites'],
//                                 ['3', 'web apps I worked on or helped maintain and improve as part of professional engagements'],
//                                 ['7', 'web apps I personally built from scratch and are available online'],
//                             ].map(([prefix, suffix]) => (
//                                 <li>
//                                     <span
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             fontWeight: 'bold',
//                                         }}
//                                     >
//                                         {prefix}
//                                     </span>
//                                     <span> {suffix}</span>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div> */}
//                 </div>

//                 <div
//                     id='backend'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row-reverse',
//                         flexWrap: 'wrap',
//                         justifyContent: 'space-around',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faServer}
//                             className='up'
//                             iconColor={colors.red}
//                             header={
//                                 // <Fragment>
//                                 `Backend\nWeb Developer`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I also have a significant experience in building servers or backends consumed by both mobile and web apps.
//                         </p>
//                     </div>
//                     <div
//                         className='right p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Languages:' />

//                         <p>C#, F#, typescript, javascript</p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>
//                             Nodejs, expressjs, .net core, .net web api, .net MVC, .net razor pages, .net blazor, .net razor components, rabbit mq, REST APIs,
//                             nginx, razor, microservices, redis
//                         </p>
//                     </div>
//                 </div>

//                 <div
//                     id='devops'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'space-around',
//                         backgroundColor: colors.section1,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faCloud}
//                             className='up'
//                             iconColor={colors.blue}
//                             header={
//                                 // <Fragment>
//                                 `DevOps,\nCloud, & Database`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I've also acquired significant experience in DevOps and cloud as they are indispensable in modern software development.
//                         </p>
//                     </div>
//                     <div
//                         className='left p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Databases:' />

//                         <p>
//                             SQLite, cloud firestore, realm DB, realm object server, postgresql, core data, entity framework, sequelize ORM, AWS dynamoDB,
//                             pouchDB, couchDB
//                         </p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>GCP, AWS, Azure, Firebase, ubuntu VM, AWS, docker, kubernetes, jenkins CI, MS app center, VS team services</p>
//                     </div>
//                 </div>

//                 <div
//                     id='desktop'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row-reverse',
//                         flexWrap: 'wrap',
//                         justifyContent: 'space-around',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                             alignItems: 'center',
//                             position: 'relative',
//                         }}
//                     >
//                         <Header
//                             icon={faWindows}
//                             iconColor={colors.purple}
//                             className='up'
//                             header={
//                                 // <Fragment>
//                                 `Desktop\nDeveloper`
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             Desktop development is where I started my career back in 2011, and I've been improving my programming skills ever since.
//                         </p>
//                     </div>
//                     <div
//                         className='right p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Languages:' />

//                         <p>C#, swift, typescript, javascript, c++, XAML</p>

//                         <Header header='Technologies:' className='mt5' style={{}} />

//                         <p>Electron, wpf, uwp, cocoa/macos, qt</p>
//                     </div>
//                     {/* <div
//                         className='right p5'
//                         style={{
//                             maxWidth,
//                         }}
//                     >
//                         <Header header='Statistics:' />
//                         <ul>
//                             {[
//                                 ['10', 'years of building desktop apps'],
//                                 ['1', 'desktop app I built as part of a professional engagement'],
//                                 ['2', 'desktop apps I personally built from scratch and are available in the Windows App Store'],
//                                 ['10+', 'desktop apps in total that I personally wrote'],
//                             ].map(([prefix, suffix]) => (
//                                 <li>
//                                     <span
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             fontWeight: 'bold',
//                                         }}
//                                     >
//                                         {prefix}
//                                     </span>
//                                     <span> {suffix}</span>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div> */}
//                 </div>

//                 {/* <div id='allaround' className='p5' style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                     flexWrap: 'wrap',
//                     justifyContent: 'space-around',
//                     backgroundColor: colors.section1,
//                 }}>
//                     <div className='p5' style={{
//                         maxWidth: maxWidth * 2,
//                         alignItems: 'center',
//                         position: 'relative',
//                     }}>
//                         <Header
//                             icon={faCode}
//                             iconColor={colors.blue}
//                             className='up'
//                             header={(
//                                 // <Fragment>
//                                 `All-around\nProgrammer`
//                                 // </Fragment>
//                             )}
//                         />
//                         <p className='up2' style={{
//                         }}>
//                             {strings.placeholder} Also a capable designer, significant experience with Adobe suite of design tools
//                         </p>
//                     </div>
//                 </div> */}

//                 {/* <div style={{
//                         maxWidth: maxWidth * 2,
//                         padding,
//                     }}>
//                         <Header
//                             header={(
//                                 <Fragment>
//                                     Technologies<br />&amp; Concepts
//                                 </Fragment>
//                             )}
//                         />

//                         <p>
//                             Python, VBA, browser extensions, automation, web scraping, selenium, imacros, pupeteer, beautiful soup python framework, jsoup, HTML agility pack, cheerio, command-line tools, code generator, clean code and architecture, OOP, MVC, MVVM, MVP, declarative &amp; state-based programming, paradigms, asynchronous code, event-based programming, multi-threading, deadlocks, diff calculations, advanced algorithms, dependency injection/IoC, service locator, FRP or functional reactive programming; design patterns such as observer, facade, singleton, mediator, visitor, delegation; memory management, ARC and garbage collection, memory leaks, stacks and heaps, cyclic references, memory profiling; git, algorthms, recursions, big-O, hashing, signing, caching, Internationalization and localization, accessibility, lint, binary searching, authentication networking, location API, media players, permissions, download managers, file system, animation, push notifications, alarm, authentication, unit testing, integration testing, instrumentation testing, deep linking
//                         </p>
//                     </div> */}

//                 <div
//                     id='skills'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'center',
//                         backgroundColor: colors.section1,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                             alignItems: 'center',
//                         }}
//                     >
//                         <Header
//                             icon={faPollH}
//                             className='up'
//                             iconColor={colors.green}
//                             header={
//                                 // <Fragment>
//                                 `Skills\nSummary`
//                                 // </Fragment>
//                             }
//                         />

//                         <p className='up2' style={{}}>
//                             I am highly proficient on 8 programming languages, but can readily write code in total of 14 programming languages. I'm also highly
//                             skilled in various platforms and frameworks.
//                         </p>
//                     </div>
//                 </div>

//                 <div
//                     id='skills2'
//                     className=''
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'center',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='up2 p5'
//                         style={{
//                             // maxWidth: maxWidth * 1.5,
//                             alignItems: 'stretch',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             flex: 1,
//                         }}
//                     >
//                         <Header
//                             style={{
//                                 alignSelf: 'center',
//                             }}
//                             header={
//                                 // <Fragment>
//                                 `Programming\nLanguages`
//                                 // </Fragment>
//                             }
//                         />
//                         <div
//                             className='mt3'
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 flexWrap: 'wrap',
//                                 maxHeight: maxSkillsHeight,
//                                 alignItems: 'stretch',
//                             }}
//                         >
//                             {languageSkills.map(param => (
//                                 <RatingView key={param.name} param={param} color={colors.blue} />
//                             ))}
//                         </div>
//                     </div>

//                     {/* <div style={{
//                         flexBasis: '100%',
//                         width: 0,
//                         height: 0,
//                     }} /> */}

//                     <div
//                         className='up4 p5'
//                         style={{
//                             // maxWidth: maxWidth * 1.5,
//                             alignItems: 'stretch',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             flex: 1,
//                         }}
//                     >
//                         <Header
//                             style={{
//                                 alignSelf: 'center',
//                             }}
//                             header={
//                                 // <Fragment>
//                                 `Platforms\n& Frameworks`
//                                 // </Fragment>
//                             }
//                         />
//                         <div
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 flexWrap: 'wrap',
//                                 maxHeight: maxSkillsHeight,
//                             }}
//                         >
//                             {platformSkills.map(param => (
//                                 <RatingView key={param.name} param={param} color={colors.green} />
//                             ))}
//                         </div>
//                     </div>
//                 </div>

//                 <div
//                     id='skills3'
//                     className=''
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'center',
//                         backgroundColor: colors.section1,
//                     }}
//                 >
//                     <div
//                         className='up2 p5'
//                         style={{
//                             // maxWidth: maxWidth * 1.5,
//                             alignItems: 'stretch',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             flex: 1,
//                         }}
//                     >
//                         <Header
//                             style={{
//                                 alignSelf: 'center',
//                             }}
//                             header={
//                                 // <Fragment>
//                                 'Databases'
//                                 // </Fragment>
//                             }
//                         />
//                         <div
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 flexWrap: 'wrap',
//                                 maxHeight: maxSkillsHeight,
//                             }}
//                         >
//                             {dbSkills.map(param => (
//                                 <RatingView key={param.name} param={param} color={colors.yellow} />
//                             ))}
//                         </div>
//                     </div>

//                     <div
//                         className='up4 p5'
//                         style={{
//                             alignItems: 'stretch',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             flex: 1,
//                         }}
//                     >
//                         <Header
//                             style={{
//                                 alignSelf: 'center',
//                             }}
//                             header={
//                                 // <Fragment>
//                                 `DevOps & Cloud`
//                                 // </Fragment>
//                             }
//                         />
//                         <div
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 flexWrap: 'wrap',
//                                 maxHeight: maxSkillsHeight,
//                             }}
//                         >
//                             {devopsSkills.map(param => (
//                                 <RatingView
//                                     key={param.name}
//                                     param={param}
//                                     // color={colors.green}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 </div>

//                 {/* <PersonalProjects /> */}
//                 <div
//                     id='career'
//                     className='p5'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         flexWrap: 'wrap',
//                         justifyContent: 'center',
//                         backgroundColor: colors.section2,
//                     }}
//                 >
//                     <div
//                         className='p5'
//                         style={{
//                             maxWidth: maxWidth * 1.5,
//                         }}
//                     >
//                         <Header
//                             icon={faBriefcase}
//                             iconColor={colors.yellow}
//                             className='up'
//                             header={
//                                 // <Fragment>
//                                 'Career\nTimeline'
//                                 // </Fragment>
//                             }
//                         />
//                         <p className='up2' style={{}}>
//                             I’ve been professionally working as a software developer since 2015, but have been writing code since 2007. I am self-taught in
//                             programming and passion is what really drives me in building programs.
//                         </p>
//                     </div>

//                     <div
//                         className='up3'
//                         style={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: 'center',
//                         }}
//                     >
//                         <div
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 alignItems: 'flex-end',
//                             }}
//                         >
//                             <div
//                                 className='down0'
//                                 style={{
//                                     display: 'flex',
//                                     flexDirection: 'row',
//                                     alignItems: 'center',
//                                     maxWidth,
//                                 }}
//                             >
//                                 <div
//                                     style={{
//                                         maxWidth,
//                                         flex: 1,
//                                     }}
//                                 >
//                                     <h1
//                                         className='size3'
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             // fontSize: 32,
//                                             color: colors.textHeader,
//                                             textAlign: 'right',
//                                         }}
//                                     >
//                                         2007
//                                     </h1>
//                                     <p
//                                         style={{
//                                             textAlign: 'right',
//                                         }}
//                                     >
//                                         First learned programming
//                                     </p>
//                                 </div>
//                                 <Line isLeft={true} />
//                             </div>

//                             {/* <div className='down2 mt-260' style={{
//                                 display: 'flex',
//                                 flexDirection: 'row',
//                                 alignItems: 'center',
//                             }}>
//                                 <div style={{
//                                     maxWidth,
//                                     flex: 1,
//                                 }}>
//                                     <h1 className='size3' style={{
//                                         fontFamily: fonts.header,
//                                         // fontSize: 32,
//                                         color: colors.textHeader,
//                                         textAlign: "right",
//                                     }}>
//                                         2017-19
//                                      </h1>
//                                     <p style={{
//                                         textAlign: 'right',
//                                     }}>
//                                         Freelance developer and self-employed; developed programs for various clients; developed and published several mobile apps in major app stores; launched several websites
//                                     </p>
//                                 </div>
//                                 <Line
//                                     isLeft={true}
//                                 />
//                             </div> */}
//                             <div
//                                 className='down2 mt-260'
//                                 style={{
//                                     display: 'flex',
//                                     flexDirection: 'row',
//                                     alignItems: 'center',
//                                 }}
//                             >
//                                 <div
//                                     style={{
//                                         maxWidth,
//                                         flex: 1,
//                                     }}
//                                 >
//                                     <h1
//                                         className='size3'
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             // fontSize: 32,
//                                             color: colors.textHeader,
//                                             textAlign: 'right',
//                                         }}
//                                     >
//                                         2019-present
//                                     </h1>
//                                     <p
//                                         style={{
//                                             textAlign: 'right',
//                                         }}
//                                     >
//                                         Lead mobile developer for a BPO company based here in the Philippines; developed web and mobile apps for company's
//                                         clients.
//                                     </p>
//                                 </div>
//                                 <Line isLeft={true} />
//                             </div>
//                         </div>

//                         <div
//                             style={{
//                                 width: 3,
//                                 alignSelf: 'stretch',
//                                 backgroundColor: colors.lines,
//                             }}
//                         />

//                         <div
//                             style={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 alignItems: 'flex-start',
//                             }}
//                         >
//                             <div
//                                 className='down3 mt-200'
//                                 style={{
//                                     display: 'flex',
//                                     flexDirection: 'row-reverse',
//                                     alignItems: 'center',
//                                 }}
//                             >
//                                 <div
//                                     style={{
//                                         maxWidth,
//                                         flex: 1,
//                                     }}
//                                 >
//                                     <h1
//                                         className='size3'
//                                         style={{
//                                             fontFamily: fonts.header,
//                                             // fontSize: 32,
//                                             color: colors.textHeader,
//                                         }}
//                                     >
//                                         2015-19
//                                     </h1>
//                                     <p>
//                                         Lead application developer (web, mobile, and desktop) in an accounting firm; developed accounting programs for the firm
//                                         and for its clients.
//                                     </p>
//                                 </div>
//                                 <Line isLeft={false} />
//                             </div>

//                             {/* <div className='down4 mt-100' style={{
//                                 display: 'flex',
//                                 flexDirection: 'row-reverse',
//                                 alignItems: 'center',
//                             }}>
//                                 <div style={{
//                                     maxWidth,
//                                     flex: 1,
//                                 }}>
//                                     <h1 className='size3' style={{
//                                         fontFamily: fonts.header,
//                                         // fontSize: 32,
//                                         color: colors.blue,
//                                     }}>
//                                         2019-present
//                                      </h1>
//                                     <p>
//                                         Senior mobile developer then lead mobile developer for a BPO company based here in the Philippines; developed mobile apps for company's clients; helped maintain and improve the company's website
//                                     </p>
//                                 </div>
//                                 <Line
//                                     isLeft={false}
//                                 />
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>

//                 <div
//                     id='contact'
//                     className='p8'
//                     style={{
//                         display: 'flex',
//                         flexDirection: 'column',
//                         flexWrap: 'wrap',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         backgroundColor: 'black',
//                         // minHeight: '100vh',
//                     }}
//                 >
//                     <div
//                         className='size5'
//                         style={{
//                             fontFamily: fonts.header,
//                             // fontSize: 36,
//                             display: 'flex',
//                             flexDirection: 'column',
//                         }}
//                     >
//                         <p
//                             className='up'
//                             style={{
//                                 maxWidth: maxWidth * 1.5,
//                             }}
//                         >
//                             I'm always open to hearing new opportunities.
//                         </p>
//                         {/* <br /> */}
//                         <a
//                             className='up2'
//                             href={`mailto:sid@sidgo.dev`}
//                             style={{
//                                 color: 'white',
//                             }}
//                         >
//                             {toRubberBandLetters(`Let's talk.`, 'sg-hoverunderline')}
//                         </a>
//                     </div>
//                 </div>
//             </div>

//             {/* <div ref={ref => refs.current['scroll'] = ref} style={{
//                 // height: `${Math.ceil(scroll)}vh`,
//                 height: 0,
//                 width: 4,
//                 backgroundColor: colors.textHeader,
//                 position: 'absolute',
//                 right: 0,
//                 top: 0,
//             }} /> */}
//         </div>
//     )
// }
