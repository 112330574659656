import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties, Fragment } from 'react'
import { colors, fonts } from '../../utils'
export function Line(props: { isLeft: boolean }) {
    return (
        <Fragment>
            <div
                className='careerline'
                style={{
                    // width: 100,
                    marginLeft: props.isLeft ? 8 : 0,
                    marginRight: !props.isLeft ? 8 : 0,
                    borderWidth: 0,
                    borderBottomWidth: 2,
                    borderColor: colors.lines,
                    borderStyle: 'dashed',
                }}
            />
            <div
                className=''
                style={{
                    width: 7,
                    height: 7,
                    backgroundColor: colors.lines,
                    marginRight: props.isLeft ? -5 : 0,
                    marginLeft: !props.isLeft ? -5 : 0,
                }}
            />
        </Fragment>
    )
}

// function StoreLinks({ web, ios, android, windows, className }: { web?: string; ios?: string; android?: string; windows?: string; className?: string }) {
//     return (
//         <div
//             className={className}
//             style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 flexWrap: 'wrap',
//                 marginTop: 16,
//                 // marginLeft: 8,
//             }}
//         >
//             {[
//                 {
//                     title: 'Web',
//                     url: web,
//                     icon: faGlobe,
//                     color: colors.red,
//                 },
//                 {
//                     title: 'iOS',
//                     url: ios,
//                     icon: faAppStore,
//                     color: colors.blue,
//                 },
//                 {
//                     title: 'Android',
//                     url: android,
//                     icon: faAndroid,
//                     color: colors.green,
//                 },
//                 {
//                     title: 'Windows',
//                     url: windows,
//                     icon: faWindows,
//                     color: colors.purple,
//                 },
//             ].map((param, index) =>
//                 !param.url ? null : (
//                     <a
//                         className=''
//                         href={param.url}
//                         style={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             alignItems: 'center',
//                             marginLeft: 8,
//                             marginTop: 16,
//                             padding: '4px 16px',
//                             backgroundColor: param.color,
//                             // borderWidth: 2,
//                             // borderColor: param.color,
//                             // borderStyle: 'solid',
//                             borderRadius: 16,
//                             color: 'white',
//                             // color: colors.textHeader,
//                         }}
//                     >
//                         <FontAwesomeIcon
//                             icon={param.icon as any}
//                             size='1x'
//                             // color={'white'}
//                         />
//                         <span
//                             style={{
//                                 marginLeft: 8,
//                                 // fontFamily: fonts.header,
//                             }}
//                         >
//                             {param.title}
//                         </span>
//                     </a>
//                 )
//             )}
//         </div>
//     )
// }

export function RatingView({
    param,
    color,
}: {
    param: {
        name: string
        rating: number
    }
    color?: string
}) {
    return (
        <div
            className='sg-minwidth-3'
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 16,
            }}
        >
            <div
                style={{
                    textAlign: 'right',
                    flex: 4,
                    // fontFamily: fonts.header,
                    // display: 'table-cell',
                }}
            >
                {param.name}
            </div>
            <div
                style={{
                    flex: 6,
                    marginLeft: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    // display: 'table-cell',
                }}
            >
                <div
                    style={{
                        // background: 'black',
                        borderWidth: 2,
                        borderColor: color ?? colors.text,
                        borderStyle: 'solid',
                        borderRadius: 4,
                        backgroundColor: color ?? colors.text,
                        // overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <div
                        className='size1'
                        style={{
                            width: `${Math.ceil(param.rating! * 10)}%`,
                            // backgroundColor: color ?? colors.text,
                            color: '#333',
                            padding: '1px 6px',
                            // fontSize: 12,
                            // fontFamily: fonts.monument,
                            // borderRadius: 16,
                        }}
                    >
                        {labelForRating[param.rating!]}
                    </div>
                    <div
                        style={{
                            backgroundColor: 'black',
                            width: `${Math.ceil((10 - param.rating!) * 10)}%`,
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            borderRadius: 4,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                    >
                        &nbsp;
                    </div>
                </div>
            </div>
            <div
                style={{
                    marginLeft: 16,
                    textAlign: 'left',
                    width: 20,
                    fontFamily: fonts.header,
                    fontSize: 12,
                    // display: 'table-cell',
                }}
            >
                {param.rating}
            </div>
        </div>
    )
}

export function Header(props: { header: string; className?: string; icon?: any; iconColor?: string; style?: CSSProperties }) {
    // return (
    //     <h1 style={{
    //         display: 'inline',
    //         fontFamily: fonts.header,
    //         fontSize: 36,
    //         margin: '0 16px 0 16px',
    //         // marginLeft: props.icon ? 16 : 0,\
    //         ...props.style,
    //     }}>{props.header}</h1>
    // )
    return (
        <div
            className={props.className}
            style={{
                // display: 'flex',
                // flexDirection: 'row',
                // alignItems: 'flex-end',
                ...props.style,
            }}
        >
            {props.icon ? (
                <div ref={ref => ref && makeRubberBand(ref)}>
                    <FontAwesomeIcon
                        // ref={ref => {

                        // }}
                        icon={props.icon}
                        size='3x'
                        color={props.iconColor ?? colors.text}
                    />
                </div>
            ) : null}

            <h1
                className={props.icon ? 'size5' : 'size3'}
                style={{
                    display: 'inline',
                    fontFamily: fonts.header,
                    // fontSize: props.icon ? 36 : 24,
                    color: colors.textHeader,
                    margin: '0 16px 0 0',
                    marginLeft: props.icon ? 16 : 0,
                }}
            >
                {toRubberBandLetters(props.header)}
            </h1>
        </div>
    )
}

// function RubberBand({ children }: {
//     children: Element
// }) {
//     const child = children[0]
//     if (!child.classList.contains('sg-rubber')) {
//         child.classList.add('sg-rubber')
//     }
//     child.onmouseover = e => {
//         const className = 'sg-rubber-animating'
//         const classList = child.classList
//         if (!classList.contains(className)) {
//             classList.add(className)
//         }
//     }

//     child.onanimationend = e => {
//         const className = 'sg-rubber-animating'
//         const classList = child.classList
//         if (classList.contains(className)) {
//             classList.remove(className)
//         }
//     }

//     return child
// }
export function makeRubberBand(element: HTMLElement) {
    if (!element.classList.contains('sg-rubber')) {
        element.classList.add('sg-rubber')
    }
    element.onmouseover = e => {
        const className = 'sg-rubber-animating'
        const classList = element.classList
        if (!classList.contains(className)) {
            classList.add(className)
        }
    }

    element.onanimationend = e => {
        const className = 'sg-rubber-animating'
        const classList = element.classList
        if (classList.contains(className)) {
            classList.remove(className)
        }
    }

    return element
}

export function toRubberBandLetters(text: string, spanClass?: string) {
    return [...text].map((letter, index) =>
        letter === '\n' ? (
            <br key={index} />
        ) : (
            <span
                key={index}
                className={'sg-rubber ' + spanClass ?? ''}
                onMouseEnter={e => {
                    const className = 'sg-rubber-animating'
                    const classList = e.currentTarget!.classList
                    if (!classList.contains(className)) {
                        classList.add(className)
                    }
                }}
                onAnimationEnd={e => {
                    const className = 'sg-rubber-animating'
                    const classList = e.currentTarget!.classList
                    if (classList.contains(className)) {
                        classList.remove(className)
                    }
                }}
                style={{
                    whiteSpace: 'pre',
                    // wordWrap: 'break-word',
                }}
            >
                {letter}
            </span>
        )
    )
}

export const labelForRating: Record<number, string> = {
    10: 'Advanced to Expert',
    9: 'Advanced',
    8: 'Intermediate',
    7: 'Intermediate',
    6: 'Elementary',
    5: 'Elementary',
    4: 'Beginner',
    3: 'Beginner',
    2: 'Beginner',
    1: 'Beginner',
    0: 'Beginner',
}

// function PersonalProjects() {
//     return (
//         <Fragment>
//             <div id='personal' className='p5' style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 flexWrap: 'wrap',
//                 justifyContent: 'space-around',
//                 backgroundColor: colors.section2,
//             }}>
//                 <div className='p5' style={{
//                     maxWidth: maxWidth * 1.5,
//                     alignItems: 'center',
//                     position: 'relative',
//                 }}>
//                     <Header
//                         className='up'
//                         icon={faTerminal}
//                         iconColor={colors.blue}
//                         header={(
//                             // <Fragment>
//                             `Selected\nPersonal Projects`
//                             // </Fragment>
//                         )}
//                     />
//                     <p className='up2' style={{
//                     }}>
//                         I've started and brought to completion dozens of projects, both personal and professional. Below are some these projects (selected based on availability not based on perceived quality).
//                             {/* {strings.placeholder} */}
//                     </p>
//                 </div>
//             </div>

//             <div id='personal3' className='p5' style={{
//                 display: 'flex',
//                 flexDirection: 'row-reverse',
//                 flexWrap: 'wrap',
//                 alignItems: 'flex-start',
//                 justifyContent: 'center',
//                 backgroundColor: colors.section1,
//             }}>
//                 <div className='p5' style={{
//                     maxWidth: maxWidth * 1,
//                     alignItems: 'center',
//                     position: 'relative',
//                 }}>
//                     <Header
//                         icon={faStickyNote}
//                         className='leftup'
//                         header={(
//                             // <Fragment>
//                             'OneKeep'
//                             // </Fragment>
//                         )}
//                     />
//                     <p className='leftup2' style={{
//                     }}>
//                         OneKeep is similar to Google Keep but ten times the number of features; also inspired by Trello; employs complex and custom algorithms on top of a clean, cross-platform architecture to implement distinct features such as formattable notes, multi-level checklists, sortable checklist items considering parent-child relationships, 7 unique ways of organizing notes, app-wide customization, calendar, team sharing, offline syncing and many more; integrated with Google and Facebook authentication; written for Windows 10, Android, iOS, and Mac using C# and Xamarin Native; previously written with native tools such as Kotlin and Swift; employs Realm DB at its data layer, and Realm Server on a GCP VM as its backend
//                         </p>

//                     <StoreLinks
//                         className='leftup3'
//                         {...onekeepLinks}
//                     />
//                 </div>

//                 <div className='up4 image-container' style={{
//                     borderWidth: 8,
//                     borderColor: 'black',
//                     borderStyle: 'solid',
//                     borderRadius: 16,
//                     overflow: 'hidden',
//                     // overflow: 'visible',
//                 }}>
//                     <img
//                         src={imageOneKeep2}
//                         className='maxwidth-800'
//                         style={{
//                             // maxWidth: maxWidthDesktop,
//                             // objectFit: 'scale-down',
//                         }}
//                     />
//                 </div>
//             </div>

//             <div id='personal2' className='p5' style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 flexWrap: 'wrap',
//                 justifyContent: 'center',
//                 backgroundColor: colors.section2,
//             }}>
//                 <div className='p5' style={{
//                     maxWidth: maxWidth * 1.2,
//                     alignItems: 'center',
//                     position: 'relative',
//                 }}>
//                     <Header
//                         icon={faMusic}
//                         className='rightup'
//                         header={(
//                             // <Fragment>
//                             'OneQuran'
//                             // </Fragment>
//                         )}
//                     />
//                     <p className='rightup2' style={{
//                     }}>
//                         OneQuran is one of the most comprehensive religious reference apps among thousands of similar apps; makes heavy use of IO and networking APIs to download files from cloud storage and from other HTTP sources; extensively employs multiple SQLite databases using both raw SQL and ORM; also features an audio player synchronized with both in-app and notification-area UI controls; utilizes various low-level APIs such as network manager, media player, location services, image manipulation and memory optimizations, and others; offers various UI customizations such as themes and fonts preferences; also built using native tools before being rewritten in Xamarin Native; accompanied by a lighter web app version built using Angular with Typescript as its frontend and NodeJS REST API as its backend, and deployed with Firebase hosting
//                         </p>

//                     <StoreLinks
//                         className='rightup3'
//                         {...onequranLinks}
//                     />
//                 </div>

//                 <div className='up4 image-container' >
//                     <img
//                         src={imageQuran}
//                         style={{
//                             maxWidth: maxWidth8Base,
//                         }}
//                     />
//                 </div>
//             </div>

//             <div id='personal1' className='p5' style={{
//                 display: 'flex',
//                 flexDirection: 'row-reverse',
//                 flexWrap: 'wrap',
//                 justifyContent: 'center',
//                 backgroundColor: colors.section1,
//             }}>
//                 <div className='p5' style={{
//                     maxWidth: maxWidth * 1.5,
//                     alignItems: 'center',
//                     position: 'relative',
//                 }}>
//                     <Header
//                         icon={faDollarSign}
//                         className='leftup'
//                         header={(
//                             // <Fragment>
//                             'Financire'
//                             // </Fragment>
//                         )}
//                     />
//                     <p className='leftup2' style={{
//                     }}>
//                         Financire is a tool for forecasting future cash balances or financial position, which is useful in planning spendings and establishing a budget.
//                         </p>

//                     <StoreLinks
//                         className='leftup3'
//                         {...financireLinks}
//                     />
//                 </div>

//                 <div className='up4 image-container' >
//                     <img
//                         src={imageFinancire1}
//                         style={{
//                             maxWidth: maxWidth11ProMax,
//                         }}
//                     />
//                 </div>
//             </div>
//         </Fragment>
//     )
// }

/* <div>
<p>
    6 years of professional experience (2015-present)
</p>
<p>
    8 years as hobbyist programmer (2007-2014)
</p>
</div> */

// Sid .. writing code since 6th grade ..
// professional career spanning 6 years, ... most recently as a senior corporate programmer, particularly lead mobile developer ...
// also independent development as I ... more than a dozen of published apps in major app stores ...
// as well as several websites ...
// passionate ...
// fullstack web developer, cross-platform mobile developer ..
// all-around programmer .. desktop, command-line tools, webscrapers, browser extensions  ... to niche ... vba, imacros, etc. ...
// but specializing in fullstack web development and cross-platform mobile development. ... also a CPA

// tab or some button to divide job positions (ios dev, android dev, full-stack web dev, others such as desktop?)

// (reorder languages, frameworks, etc. for each tab)

// Programming langauges here ...

// Frameworks here ...

// (breakdown each language and framework to tools, projects, etc. and tell experience ..
// remember in POV of hiring manager or recruiter .. both technical and non-technical)

// education ... cpa ... consistent dean's lister ... candidate for the highest honor until i focused ...

// clean code, algorithm, object-oriented, servers, cloud, testing,

// languages:
// typescript
// javascript
// SQL
// swift
// kotlin
// java
// objective-c
// c#
// f#
// visual basic
// dart
// c++
// python
// elm

// front-end web frameworks:
// react is my favorite and where i have years of experience
// but i also have a considerable experience with angular typescript and angular dart

// back-end web frameworks:
// both nodejs and .net core
// nginx
// sqlite
// postgres sql
// realm database and realm object server
// most experience GCP and Firebase .. considerable in AWS and some in Azure ...

// native mobile development:
// both ios and android .. mostly with swift and kotlin ... but also written native mobile apps
// using java and objective-c .. and partly in react-native apps because they're written in the latter languages
// if you want to implement custom native functionality ...

// cross-platform frameworks:
// mainly use react-native which allows me to reuse code in the web version of my apps written with react
// also extensive and years of experience with Xamarin Native and Xamarin forms
// considerable experience with flutter though

// desktop development:
// significant experience with Electron as well as with native UWP and WPF apps ... also developed cocoa or macos apps

// frameworks:
// tools:
// git

// -----------
// react stack:
// react
// react-native
// electron
// chrome extensions
// nodejs with express
// native mobile

// typescript
// javascript

// bootstrap
// sass
// less
// webpack
// babel
// typescript
// jest
// ----------
// .net stack:

// -------

// function SideBar(){
//     return (
//         <div style={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: colors.nav,
//             position: 'relative',
//             borderWidth: 0,
//             borderRightWidth: 1,
//             borderColor: '#191919',
//             borderStyle: 'solid',
//         }}>
//             <div ref={ref => refs.current['highlight'] = ref} className='sg-highlight' style={{
//                 height: 48,
//                 width: 48,
//                 top: 0,
//                 backgroundColor: '#000',
//             }}>

//             </div>
//             {[
//                 {
//                     icon: faUser,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faApple,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faAndroid,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faReact,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faChrome,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faServer,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faCloud,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faWindows,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faCode,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faPollH,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faUserShield,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faBriefcase,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faStream,
//                     onClick: () => {

//                     },
//                 },
//                 {
//                     icon: faEnvelope,
//                     onClick: () => {

//                     },
//                 },
//             ].map((param, index) => (
//                 <button
//                     id={`icon${index}`}
//                     className='icon-button'
//                     onClick={param.onClick}
//                     style={{
//                         width: 48,
//                         height: 48,
//                         backgroundColor: 'transparent',
//                         borderWidth: 0,
//                         zIndex: 10,
//                     }}
//                 >
//                     <FontAwesomeIcon
//                         icon={param.icon as IconDefinition}
//                         size='lg'
//                         color='#999'

//                     />
//                 </button>
//             ))}
//         </div>
//     )
// }
