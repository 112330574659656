import { Location } from 'history'
import React, { Fragment } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import '../css/animations.css'
import '../css/app.css'
import '../css/common.css'
import '../css/fonts.css'
import '../css/modals.css'
import '../css/navmenu.css'
import { Home } from './home'

export default function App(props: {}) {
    return (
        <Fragment>
            {/* <AppNav /> */}
            <AppBody />
        </Fragment>
    )
}
function AppBody() {
    const actualLocation = useLocation<any>()
    const modalBackgroundLocation = actualLocation.state?.modalBackgroundLocation as Location<any>
    const location = modalBackgroundLocation ?? actualLocation
    return (
        <div
            style={{
                // backgroundColor: colors.light9,
                height: '100%',
                flex: 1,
                // setting this will get this scrolled even though it's the child that got scrolled
                // but not setting it will make home strange to user since only the home container is scrollable
                overflow: 'hidden',
                // display: 'flex',
            }}
        >
            <Switch location={location}>
                <Route exact path='/' component={Home} />
            </Switch>
        </div>
    )
}
