import { setAttributes } from '../../utils'

export function onScroll(
    root: HTMLDivElement,
    refs: React.MutableRefObject<Record<string, HTMLDivElement | null | undefined>>,
    lastTopIndex: React.MutableRefObject<number>
) {
    const scrollTop = root.scrollTop
    const scrollBottom = scrollTop + root.offsetHeight
    const rootWidth = root.offsetWidth
    const isSmall = rootWidth <= 480

    let t0 = isSmall ? 60 : 80
    let t1 = isSmall ? 100 : 130
    let t2 = isSmall ? 160 : 230
    let t3 = isSmall ? 220 : 330
    let t4 = isSmall ? 300 : 430

    const minOpacity = 0

    if (scrollTop <= root.offsetHeight) {
        const intro1 = refs.current['intro1']
        const intro2 = refs.current['intro2']
        if (intro1 && intro2) {
            const percent = scrollTop / root.offsetHeight
            intro1.style.transform = `translateY(${-t3 * percent}px)`
            intro2.style.transform = `translateY(${-t2 * percent}px)`
        }
    }

    for (let i = lastTopIndex.current; i < root.children.length; i++) {
        const child = root.children[i] as HTMLDivElement
        const offsetTop = child.offsetTop
        const offsetBottom = offsetTop + child.offsetHeight
        let offsetPercentage = (scrollBottom - offsetTop) / child.offsetHeight
        offsetPercentage = Math.min(1, offsetPercentage)
        offsetPercentage = Math.max(0, offsetPercentage)
        const offsetInversePercentage = 1 - offsetPercentage

        // if (offsetPercentage >= 0 && offsetPercentage <= 1) {
        if (scrollBottom >= offsetTop && scrollTop <= offsetBottom) {
            const ups = child.getElementsByClassName('up')
            const ups2 = child.getElementsByClassName('up2')
            const ups3 = child.getElementsByClassName('up3')
            const ups4 = child.getElementsByClassName('up4')

            const downs0 = child.getElementsByClassName('down0')
            const downs = child.getElementsByClassName('down')
            const downs2 = child.getElementsByClassName('down2')
            const downs3 = child.getElementsByClassName('down3')
            const downs4 = child.getElementsByClassName('down4')

            const lefts = child.getElementsByClassName('left')
            const rights = child.getElementsByClassName('right')

            const leftups = child.getElementsByClassName('leftup')
            const leftups2 = child.getElementsByClassName('leftup2')
            const leftups3 = child.getElementsByClassName('leftup3')
            const leftups4 = child.getElementsByClassName('leftup4')
            const rightups = child.getElementsByClassName('rightup')
            const rightups2 = child.getElementsByClassName('rightup2')
            const rightups3 = child.getElementsByClassName('rightup3')
            const rightups4 = child.getElementsByClassName('rightup4')

            setAttributes(ups, `translateY(${t1 * offsetInversePercentage}px)`)
            setAttributes(ups2, `translateY(${t2 * offsetInversePercentage}px)`)
            setAttributes(ups3, `translateY(${t3 * offsetInversePercentage}px)`)
            setAttributes(ups4, `translateY(${t4 * offsetInversePercentage}px)`)

            setAttributes(leftups, `translateY(${t1 * offsetInversePercentage}px)`)
            setAttributes(leftups2, `translateY(${t2 * offsetInversePercentage}px)`)
            setAttributes(leftups3, `translateY(${t3 * offsetInversePercentage}px)`)
            setAttributes(leftups4, `translateY(${t4 * offsetInversePercentage}px)`)

            setAttributes(rightups, `translateY(${t1 * offsetInversePercentage}px)`)
            setAttributes(rightups2, `translateY(${t2 * offsetInversePercentage}px)`)
            setAttributes(rightups3, `translateY(${t3 * offsetInversePercentage}px)`)
            setAttributes(rightups4, `translateY(${t4 * offsetInversePercentage}px)`)

            setAttributes(downs0, `translateY(${-t0 * offsetInversePercentage}px)`)
            setAttributes(downs, `translateY(${-t1 * offsetInversePercentage}px)`)
            setAttributes(downs2, `translateY(${-t2 * offsetInversePercentage}px)`)
            setAttributes(downs3, `translateY(${-t3 * offsetInversePercentage}px)`)
            setAttributes(downs4, `translateY(${-t4 * offsetInversePercentage}px)`)

            const opacity = minOpacity + (1 - minOpacity) * offsetPercentage
            // const factor = 0.5
            // setAttributes(lefts, `translate(${t1 * factor * offsetInversePercentage}px, ${t1 * offsetInversePercentage}px)`, opacity)
            // setAttributes(rights, `translate(${-t1 * factor * offsetInversePercentage}px, ${t1 * offsetInversePercentage}px)`, opacity)
            setAttributes(lefts, `translateY(${t1 * offsetInversePercentage}px)`, opacity)
            setAttributes(rights, `translateY(${t1 * offsetInversePercentage}px)`, opacity)

            // setAttributes(leftups, `translate(${t1 * factor * offsetInversePercentage}px, ${t1 * offsetInversePercentage}px)`, opacity)
            // setAttributes(leftups2, `translate(${t1 * factor * offsetInversePercentage}px, ${t2 * offsetInversePercentage}px)`, opacity)
            // setAttributes(leftups3, `translate(${t1 * factor * offsetInversePercentage}px, ${t3 * offsetInversePercentage}px)`, opacity)
            // setAttributes(leftups4, `translate(${t1 * factor * offsetInversePercentage}px, ${t4 * offsetInversePercentage}px)`, opacity)
            // setAttributes(rightups, `translate(${-t1 * factor * offsetInversePercentage}px, ${t1 * offsetInversePercentage}px)`, opacity)
            // setAttributes(rightups2, `translate(${-t1 * factor * offsetInversePercentage}px, ${t2 * offsetInversePercentage}px)`, opacity)
            // setAttributes(rightups3, `translate(${-t1 * factor * offsetInversePercentage}px, ${t3 * offsetInversePercentage}px)`, opacity)
            // setAttributes(rightups4, `translate(${-t1 * factor * offsetInversePercentage}px, ${t4 * offsetInversePercentage}px)`, opacity)
        }
        // if (scrollBottom < currentOffsetTop) {
        //     // console.log(currentChild.id);
        //     const previousChild = i == 0 ? currentChild : root.children[i - 1] as HTMLDivElement
        //     const previousOfPreviousChild = i <= 1 ? currentChild : root.children[i - 2] as HTMLDivElement
        //     const previousOffsetTop = previousChild.offsetTop
        //     const offsetHalf = previousOffsetTop + (previousChild.offsetHeight / 2)
        //     // const icon = document.getElementById(`icon${i - 1}`) as HTMLElement
        //     // if (icon) {
        //     //     const highlight = refs.current['highlight']
        //     //     if (highlight) {
        //     //         highlight.style.transform = `translate(0px, ${icon.offsetTop}px)`
        //     //     }
        //     // }
        //     // const child = scrollBottom >= offsetHalf ? previousChild : previousOfPreviousChild
        //     // const svg = child.getElementsByTagName('svg').item(0)
        //     // if (scrollbar) {
        //     //     console.log('setting color: ' + child.id);
        //     //     scrollbar.style.backgroundColor = (svg?.getAttribute('color')) ?? colors.textHeader
        //     // }
        //     // lastTopIndex.current = i
        //     break
        // }
    }
}
